import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import ProfileContainer from 'components/Sections/Profile/ProfileContainer'
import PageTitle from 'components/UIElements/PageTitle'
import RichText from 'components/UIElements/RichText'
import Blockquote from 'components/UIElements/Blockquote'
// Models
import { IProfileHeader, IProfileMenuItem } from 'models/Profile'
import { IOptionalMetaProps } from 'models/SEO'
import { IUser } from 'models/User'

type UserPageProps = {
    user: IUser
    translations: {
        totalCount: number
    }
    articles: {
        totalCount: number
    }
}

type Context = {
    slug: string
}

const UserPage: React.FC<PageProps<UserPageProps, Context>> = ({ data }) => {
    const user = data.user
    const totalTranslations = data.translations.totalCount
    const totalArticles = data.articles.totalCount
    const seo: IOptionalMetaProps = {
        title: user.name,
        description: `Perfil de ${user.name}`,
        image: user.avatar.file?.url,
    }

    const { name, avatar, cover, username } = user
    const { spotify, twitter, youtube, instagram, web } = user

    const headerProps: IProfileHeader = {
        type: 'Usuario',
        name,
        subname: `@${username}`,
        avatar,
        cover,
        social: {
            spotify,
            twitter,
            youtube,
            instagram,
            web,
        },
    }

    const menu: IProfileMenuItem[] = [
        { text: `Perfil`, to: `/users/${username}` },
        {
            text: `Artículos`,
            to: `/users/${username}/articles`,
            count: totalArticles,
        },
        {
            text: `Traducciones`,
            to: `/users/${username}/translations`,
            count: totalTranslations,
        },
    ]

    const { description } = user

    return (
        <Layout seo={seo}>
            <ProfileContainer menu={menu} {...headerProps}>
                <PageTitle title="Información" />
                {description ? (
                    <RichText content={description} />
                ) : (
                    <Blockquote>{user.name} aun no ha escrito nada.</Blockquote>
                )}
            </ProfileContainer>
        </Layout>
    )
}

export default UserPage

export const pageQuery = graphql`
    query UserPage($username: String!) {
        user: contentfulUsers(username: { eq: $username }) {
            name
            username
            birthday

            description {
                raw
            }

            avatar {
                gatsbyImageData(width: 200)
                file {
                    url
                }
            }
            cover {
                gatsbyImageData(layout: CONSTRAINED)
            }

            twitter
            instagram
            spotify
            youtube
            web
        }

        translations: allContentfulSongs(
            filter: {
                translators: { elemMatch: { username: { eq: $username } } }
            }
        ) {
            totalCount
        }

        articles: allContentfulArticles(
            sort: { fields: date, order: DESC }
            filter: { author: { username: { eq: $username } } }
        ) {
            totalCount
            nodes {
                title
                slug
                date
                excerpt {
                    text: excerpt
                }
                author {
                    name
                    username
                    avatar {
                        gatsbyImageData(layout: CONSTRAINED)
                    }
                }
                categories {
                    name
                    slug
                    color
                }
                thumbnail {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
        }
    }
`
